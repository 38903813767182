// import { getArea } from "ol/sphere"
// import Feature from "ol/Feature"
import Polygon from "ol/geom/Polygon"
import Point from "ol/geom/Point"
// import pcp from "2d-polygon-contains-polygon"

function pointsListToWKT(pointsList){
  var g = 'MULTIPOINT ('
  for(let i = 0; i < pointsList.length; i++){
    if(pointsList[i].geometry!=null){
      let el = pointsList[i].geometry.coordinates
      g += el[0]+' '+el[1]+', '
    }
  }
  g = g.slice(0, -2)
  g += ')'
  return g
}

function pointsListToEWKT(srid,pointsList){
  var wkt = pointsListToWKT(pointsList)
  return 'SRID='+srid+';'+wkt
}

function polygonsListToWKT(outsidePolygon,insidePolygons){
  try{
    let polygonsList = insidePolygons
    polygonsList.unshift(outsidePolygon)
    let g = 'POLYGON ('
    polygonsList.forEach(polygon => {
      polygon.geometry.coordinates.forEach(coordinates => {
        g += '('
        coordinates.forEach(coordinate => {
          g += coordinate[0]+' '+coordinate[1]+','
        })
        g = g.slice(0, -1)
        g += '),'
      })
    })
    g = g.slice(0, -1)
    g += ')'
    return g
  } catch(error){
    console.log(error)
  }
}

function polygonsListToEWKT(srid,outsidePolygon,insidePolygons){
  try{
    let geom = polygonsListToWKT(outsidePolygon,insidePolygons)
    let g = 'SRID='+srid+';'+geom
    return g
  } catch(error){
    console.log(error)
  }
}

// function polygonArea(polyGeom) {
//   try {
//     let feature = new Feature({geometry: new Polygon(polyGeom)})
//     return getArea(feature.getGeometry())
//   } catch(error){
//     console.log(error)
//   }
// }

function inside(point, vs) {
  // array of coordinates of each vertex of the polygon
  // var polygon = [ [ 1, 1 ], [ 1, 2 ], [ 2, 2 ], [ 2, 1 ] ];
  // inside([ 1.5, 1.5 ], polygon); // true
  console.log('point',point)
  console.log('vs',vs)
  var x = point[0], y = point[1]
  var inside = false
  for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
      var xi = vs[i][0], yi = vs[i][1]
      var xj = vs[j][0], yj = vs[j][1]
      var intersect = ((yi > y) != (yj > y))
          && (x < (xj - xi) * (y - yi) / (yj - yi) + xi)
      if (intersect) inside = !inside
  }
  return inside
}

function pointsInsidePolygon(polygonCoords,pointsArray) {
  //polygonCoords: [[[-6653078,-1069710],[-7070527,-2452508],[-5844273,-2139422],[-6653078,-1069710]]]
  const polyGeom = new Polygon(polygonCoords)
  // console.log('area',(polyGeom.getArea()))
  pointsArray.forEach(point => {
    const pointGeom = new Point([point[0],point[1]])
    // console.log('pointGeom',pointGeom)
    if(!polyGeom.intersect(pointGeom)){
      alert('Point outside polygon',polyGeom,pointGeom)
      console.log('inside pointsInsidePolygon end false')
      return false
    }
  })
  console.log('inside pointsInsidePolygon end true')
  return true
}

function geomsIntersects(geoms) {
  for(var i=0; i<geoms.length-1; ++i) {
    let geom1 = geoms[i]
    for(var j=i+1; j<geoms.length; ++j) {
      let geom2 = geoms[j]
      let intersects12 = geom1.intersects(geom2)
      if(intersects12) {
        alert("Intersection",geom1,geom2)
      }
      else{
        alert("No Intersection",geom1,geom2)
      }

    }
  }
}

function featuresIntersects(features) {
  for(var i=0; i<features.length-1; ++i) {
    let feat1 = features[i];
    for(var j=i+1; j<features.length; ++j) {
      let feat2 = features[j];
      let intersects12 = feat1.geometry.intersects(feat2.geometry);
      if(intersects12) {
        alert("Intersection");
      }
    }
  }
}

function geomAddPoint(basePoint,point){
  var g = 'SRID=3857;MULTIPOINT ('
  if(basePoint.geometry!=null){
    basePoint.geometry.coordinates.forEach(el => {
      g += el[0]+' '+el[1]+', '
    });
  }
  g += point.geometry.coordinates[0][0]+' '+point.geometry.coordinates[0][1]+')'
  return g
}

function geomAddPolygon(basePolygon,polygon){
  var g = 'SRID=3857;POLYGON (('
  if(basePolygon.geometry){
    basePolygon.geometry.coordinates.forEach(ring => {
      ring.forEach(element => {
        g += element[0]+' '+element[1]+', '
      });
      g = g.substring(0, g.length - 2)
      g += '),('
    })
  }
  polygon.geometry.coordinates[0].forEach(element => {
    g += element[0]+' '+element[1]+', '
  });
  g = g.substring(0, g.length - 2)
  g += '))'
  return g
}

export {
  pointsListToWKT,
  polygonsListToWKT,
  pointsListToEWKT,
  polygonsListToEWKT,
  // polygonArea,
  pointsInsidePolygon,
  geomsIntersects,
  featuresIntersects,
  geomAddPoint,
  geomAddPolygon,
  inside,
}
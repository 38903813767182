<template>
    <div>
        <!-- 1. Create the button that will be clicked to select a file -->
        <v-btn text :loading="isSelecting" @click="handleFileImport">
        <v-icon>mdi-file-upload-outline</v-icon>
        Upload LineString {{ numbOfLS }}
        </v-btn>
        <!-- Create a File Input that will be hidden but triggered with JavaScript -->
        <input ref="uploader" class="d-none" type="file" accept=".geojson" @change="onFileChanged">
    </div>
</template>

<script>
    export default {
        name: 'Example',
        props:{
            numbOfLS: undefined,
        },
        data(){
            return {
                isSelecting: false,
                selectedFile: null
            }
        },
        methods: {
            handleFileImport() {
                this.isSelecting = true;

                // After obtaining the focus when closing the FilePicker, return the button state to normal
                window.addEventListener('focus', () => {
                    this.isSelecting = false
                }, { once: true });
                
                // Trigger click on the FileInput
                this.$refs.uploader.click();
            },
            onFileChanged(e) {
                const files = e.target.files;
                if (files.length <= 0) {
                    return false
                }
                const fr = new FileReader()
                fr.onload = e => {
                    const result = JSON.parse(e.target.result)
                    this.$emit("inputVector",result)
                    // const formatted = JSON.stringify(result, null, 2)
                    // console.log('formatted',formatted)
                }
                fr.readAsText(files.item(0))
            },
        }
    }
</script>